import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp } from '@ionic/react';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import './theme/theme.css';

import UserProvider from './providers/UserProvider';
import Routing from './Routing';
import ToastContainer from './components/ToastContainer';

import { StoreProvider, createStore } from 'easy-peasy';
import { Provider } from 'react-redux';
import model from './model';

const store = createStore(model);

const App = () => {

  return (
  <StoreProvider store={store}>
  <Provider store={store}>
  <UserProvider>
    <IonApp>
      <ToastContainer />
      <Routing />
    </IonApp>
  </UserProvider>
  </Provider>
  </StoreProvider>
  );
}

export default App;
